import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Table from "~/components/Table/table";

const tHead = [
  "",
  "Prescription medicine",
  "Over-the-counter medicine",
  "Dietary supplement "
]

const tBodyStudent = [
  ["Regulations", "", "", ""],
  ["Scientific evidence", "", "", ""],
  ["Potential risks", "", "", ""]
]

const tBodyTeacher = [
  ["Regulations", "1", "2", "3"],
  ["Scientific evidence", "1", "2", "3"],
  ["Potential risks", "3", "2", "1"]
]

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={12}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 12</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>In this lesson, you have learned about prescription drugs, OTC drugs, and dietary supplements.</p>
            <p>Draw a chart like the following in your science notebook.</p>
            <div className="my-4">
              <Table
                thead={tHead}
                tbody={tBodyStudent}
              />
            </div>
            <p>Rank each type of product for each category. For example, which product (prescription medicine, OTC medicine, or dietary supplement) is most highly regulated? Give that product a 1. Give the product that is least regulated a 3. Give the remaining product a 2.</p>
            <p>Continue for the other categories using a 1 for the highest level and 3 for the lowest. Be prepared to explain your rankings.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>This screen asks students to summarize what they have learned about the different types of products. The table below gives sample answers for how students might rank the different products for the different categories.</p>
            <div className="my-4">
              <Table
                thead={tHead}
                tbody={tBodyTeacher}
              />
            </div>
            <p>Students may rank the potential risks a bit differently, which is OK if their justifications match their rankings. Dietary supplements could rank as the highest risk because they (usually) have the least amount of evidence supporting the claims, or they could be ranked lower because they (usually) have less effect on the body (depending on the situation). Prescription medications could be said to have the lowest potential risk because of the amount of evidence accumulated before they are put on the market and because they are used under the supervision of a doctor. However, you could argue that they could have a higher risk if they were used inappropriately.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

